<template>
    <div>
        <Form v-model="businessUnitSelectData" ref="form"/>

        <h2 class="mt-12">{{ text.user_pending_advert_table }}</h2>
        <div class="table-scroller table-shadow">
            <table class="pending">
                <thead>
                <tr>
                    <th class="w-3/12">{{ text.advert_name }}</th>
                    <th class="w-3/12">{{ text.business_unit }}</th>
                    <th class="w-2/12">{{ text.created_by }}</th>
                    <th class="w-1/12">{{ text.date_live }}</th>
                    <th class="w-1/12">{{ text.date_expiry }}</th>
                    <th class="w-2/12">{{ text.actions }}</th>
                </tr>
                </thead>
                <tbody v-if="pendingTableData.length > 0">
                <tr v-for="row in pendingTableData" :key="row.id">
                    <td>
                        <router-link :to="{ name: 'AdminAdvertsEdit', params: { 'id' : row.id } }"
                                     :title="text.edit + ' ' + row.name" class="default-action">{{ row.name }}
                        </router-link>
                    </td>
                    <td>{{ row.businessUnit.name }}</td>
                    <td>{{ row.ownerName | createdByName(row.isOwner) }}</td>
                    <td>{{ row.liveDate | formatDate }}</td>
                    <td>{{ row.expiryDate | formatDate }}</td>
                    <td>
                        <ViewAction :routeName="'AdminAdvertsView'" :routeParams="{ 'id' : row.id }"
                                    :linkName="row.name"/>
                        <ExportAction :routeName="'AdminAdvertsExport'" :routeParams="{ 'id' : row.id }"
                                      :linkName="row.name" v-if="row.published"/>
                        <EditAction :routeName="'AdminAdvertsEdit'" :routeParams="{ 'id' : row.id }"
                                    :linkName="row.name"/>
                        <CopyAction :linkName="row.name" @actionCalled="copyAdvert(row.id, 'AdminAdvertsEdit')"/>
                        <ArchiveAction :linkName="row.name"
                                       @actionCalled="archiveAdvert(row.id, 'loadPendingAdvertsList')"/>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="6">{{ text.no_results }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="pendingTablePagination.totalPages > 0" :pagination="pendingTablePagination" @go-previous="refreshPendingAdvertsList" @go-next="refreshPendingAdvertsList"/>


        <h2 class="mt-4">{{ text.admin_active_advert_table }}</h2>
        <div class="table-scroller table-shadow">
            <table class="active">
                <thead>
                <tr>
                    <th class="w-3/12">{{ text.advert_name }}</th>
                    <th class="w-3/12">{{ text.business_unit }}</th>
                    <th class="w-2/12">{{ text.created_by }}</th>
                    <th class="w-1/12">{{ text.date_live }}</th>
                    <th class="w-1/12">{{ text.date_expiry }}</th>
                    <th class="w-2/12">{{ text.actions }}</th>
                </tr>
                </thead>
                <tbody v-if="activeTableData.length > 0">
                <tr v-for="row in activeTableData" :key="row.id">
                    <td>
                        <router-link :to="{ name: 'AdminAdvertsEdit', params: { 'id' : row.id } }"
                                     :title="text.edit + ' ' + row.name" class="default-action">{{ row.name }}
                        </router-link>
                    </td>
                    <td>{{ row.businessUnit.name }}</td>
                    <td>{{ row.ownerName | createdByName(row.isOwner) }}</td>
                    <td>{{ row.liveDate | formatDate }}</td>
                    <td>{{ row.expiryDate | formatDate }}</td>
                    <td>
                        <ViewAction :routeName="'AdminAdvertsView'" :routeParams="{ 'id' : row.id }"
                                    :linkName="row.name"/>
                        <ExportAction :routeName="'AdminAdvertsExport'" :routeParams="{ 'id' : row.id }"
                                      :linkName="row.name" v-if="row.published"/>
                        <EditAction :routeName="'AdminAdvertsEdit'" :routeParams="{ 'id' : row.id }"
                                    :linkName="row.name"/>
                        <CopyAction :linkName="row.name" @actionCalled="copyAdvert(row.id, 'AdminAdvertsEdit')"/>
                        <ArchiveAction :linkName="row.name"
                                       @actionCalled="archiveAdvert(row.id, 'loadPendingAdvertsList')"/>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="6">{{ text.no_results }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="activeTablePagination.totalPages > 0" :pagination="activeTablePagination" @go-previous="refreshActiveAdvertsList" @go-next="refreshActiveAdvertsList"/>


        <h2>{{text.admin_draft_advert_table}}</h2>
        <div class="table-scroller">
            <table class="drafts">
                <thead>
                <tr>
                    <th class="w-3/12">{{ text.advert_name }}</th>
                    <th class="w-3/12">{{ text.business_unit }}</th>
                    <th class="w-2/12">{{ text.created_by }}</th>
                    <th class="w-2/12">{{ text.date_last_modified }}</th>
                    <th class="w-2/12">{{ text.actions }}</th>
                </tr>
                </thead>
                <tbody v-if="draftTableData.length > 0">
                <tr v-for="row in draftTableData" :key="row.id">
                    <td><router-link :to="{ name: 'AdminAdvertsView', params: { 'id' : row.id } }" :title="text.view + ' ' + row.name" class="default-action">{{row.name}}</router-link></td>
                    <td>{{ row.businessUnit.name }}</td>
                    <td>{{ row.ownerName | createdByName(row.isOwner) }}</td>
                    <td>{{row.currentVersionDate | formatDate}}</td>
                    <td>
                        <ViewAction :routeName="'AdminAdvertsView'" :routeParams="{ 'id' : row.id }" :linkName="row.name"/>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="5">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="draftTablePagination.totalPages > 0" :pagination="draftTablePagination" @go-previous="loadDraftAdvertsList" @go-next="loadDraftAdvertsList" />
    </div>
</template>

<script>
import lang from "@/shared/lang";
import {log} from "@/shared/utils";
import {showDefaultServerErrorMessage} from "@/shared/utils";
import {getBusinessUnits} from "@/shared/dataservice";

import {formatContentMixin} from "@/views/mixins/formatContent";
import {advertActionsMixin} from "@/views/mixins/advertActions";
import {formWrapperMixin} from "@/views/mixins/formwrapper";

import Pagination from "@/components/pagination";
import EditAction from "@/components/actionlinks/edit";
import ViewAction from "@/components/actionlinks/view";
import ExportAction from "@/components/actionlinks/export";
import CopyAction from "@/components/actionlinks/copy";
import ArchiveAction from "@/components/actionlinks/archive";

import Form from "@/components/form/form";

export default {
    name: 'AdminAdvertList',
    components: {Form, Pagination, ViewAction, ExportAction, EditAction, CopyAction, ArchiveAction},
    mixins: [formatContentMixin, advertActionsMixin, formWrapperMixin],
    data() {
        return {
            activeTableData: [],
            activeTablePagination: [],
            pendingTableData: [],
            pendingTablePagination: [],
            draftTableData: [],
            draftTablePagination: [],
            businessUnitSelectData: [
                {
                    id: 1,
                    type: 'select',
                    name: 'businessUnit',
                    label: 'Filter by department',
                    placeholder: "",
                    tooltip: "",
                    value: "",
                    options: []
                },
            ]
        }
    },
    computed: {
        text() {
            return lang;
        },
        selectedBusinessUnitValue() {
            return this.businessUnitSelectData.find(row => row.name === 'businessUnit').value;
        },
        defaultBusinessUnitSelectOption() {
            return {value: "all", text: this.text.showing_all_business_units};
        },
        businessUnit() {
            return this.$route.params.businessUnit ? this.$route.params.businessUnit : "all";
        },
        businessUnitFilter() {
            return this.businessUnit === "all" ? [] : [{name: "businessUnit", value: this.businessUnit}];
        },
    },
    methods: {
        async loadBusinessUnits() {
            let result = await getBusinessUnits();

            log(["Business Unit Result", result, this.businessUnitSelectData]);

            if (result.status === 200) {
                let newOptions = [this.defaultBusinessUnitSelectOption];
                result.data.data.forEach(businessUnit => {
                    newOptions.push({value: businessUnit.slug, text: businessUnit.name});
                });

                this.businessUnitSelectData.find(row => row.name === 'businessUnit').options = newOptions;
            } else {
                showDefaultServerErrorMessage("Load Business Unit");
            }
        },
        updateBusinessUnitSelect() {
            this.businessUnitSelectData.find(row => row.name === 'businessUnit').value = this.businessUnit;
        },
        async refreshAdvertLists(page = 1) {
            await this.refreshDraftAdvertsList(page);
            await this.refreshPendingAdvertsList(page);
            await this.refreshActiveAdvertsList(page);
        },
        async refreshPendingAdvertsList(page = 1) {
            await this.loadPendingAdvertsList(page, this.businessUnitFilter);
        },
        async refreshActiveAdvertsList(page = 1) {
            await this.loadActiveAdvertsList(page, this.businessUnitFilter);
        },
        async refreshDraftAdvertsList(page = 1) {
            await this.loadDraftAdvertsList(page, this.businessUnitFilter);
        }
    },
    async mounted() {
        this.updateBusinessUnitSelect();
        await this.loadBusinessUnits(1);
        this.refreshAdvertLists(1);
    },
    watch: {
        async $route() {
            this.updateBusinessUnitSelect();
            this.refreshAdvertLists(1);
        },
        selectedBusinessUnitValue() {
            log(["Selected business unit changed", this.selectedBusinessUnitValue, this.businessUnit]);
            if (this.selectedBusinessUnitValue != this.businessUnit) {
                log(["Change route"]);
                this.$router.push({name: 'AdminAdvertsList', params: {'businessUnit': this.selectedBusinessUnitValue}});
            }
        }
    }
}
</script>
